.schema-faq__question + .schema-faq__question {
    --section-vertical-gap: 12px;
    margin-top: var(--section-vertical-gap);

    @include apply-screen-size(tablet-md) {
        --section-vertical-gap: 24px;
    }
}

.schema-faq__description {
    padding: 8px 24px;
}

.schema-faq__question-answer-faq .paragraph {
    margin-top: 8px;
    padding: 0;
}

.schema-faq--ordered {
    list-style: auto;
}
