.breadcrumbs {
    display: inline;
    vertical-align: middle;
}

.breadcrumbs__item {
    display: inline;
    vertical-align: middle;
    color: #444647;

    .icon {
        display: inline-block;
        vertical-align: middle;
    }
}

.breadcrumbs__icon {
    display: inline-block;
    vertical-align: middle;
    padding-left: 10px;
    padding-right: 10px;

    .icon {
        --breadcrumbs-icon-size: 16px;
        width: var(--breadcrumbs-icon-size);
        height: var(--breadcrumbs-icon-size);

        @include apply-screen-size(desktop-sm) {
            --breadcrumbs-icon-size: 20px;
        }
    }
}

.breadcrumbs__url {
    display: inline;
    vertical-align: middle;
    color: #444647;
}
