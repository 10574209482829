.logotype {
    color: var(--logo-color, #fff);

    .header__logotype-link & {
        pointer-events: none;
    }
}

.logotype__image {
    display: block;
    width: var(--logo-width, 160px);
    height: auto;
    fill: var(--logo-color, #FFC629);
}

.logotype--inherit {
    color: inherit;
}
