.logo {
    display: block;
    width: var(--width, 100%);
    height: var(--height, 100%);

    > svg {
        display: block;
        width: 100%;
        height: 100%;
        fill: currentColor;
    }
}

.logo--color-primary {
    color: var(--color-primary);
}

.logo--color-inherited {
    color: currentColor;
}
