@import 'typography.reference.scss';

@keyframes draw-dash {
    to {
        stroke-dashoffset: 0;
    }
}
@keyframes draw-underline {
    to {
        background-size: 100% 2px;
    }
}

@for $i from 1 through 4 {
    .animated--order-#{$i} {
        animation-delay: $i * 400ms !important;
    }
}

.is-responsive {
    // safari clamp bug
    // https://stackoverflow.com/questions/37070694/font-size-calc-dynamic-size-safari-failing
    // still works not all the time and need investigation
    min-height: 0vw;
}

.h1 {
    @include apply-text-inherited();
    @include apply-text-pallete();
    @include apply-typography-header1();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @include apply-typography-header1(true);
    }

    & p {
        @include apply-text-inherited();
        @include apply-text-pallete();
        @include apply-typography-header1();

        &.is-responsive {
            @include apply-typography-header1(true);
        }
    }

    > a {
        color: inherit;

        &:hover {
            opacity: 0.85;
        }
    }
}

.h2 {
    @include apply-text-inherited();
    @include apply-text-pallete();
    @include apply-typography-header2();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @include apply-typography-header2(true);
    }

    & p {
        @include apply-text-inherited();
        @include apply-text-pallete();
        @include apply-typography-header2();

        &.is-responsive {
            @include apply-typography-header2(true);
        }
    }

    > a {
        color: inherit;

        &:hover {
            opacity: 0.85;
        }
    }
}

.h3 {
    @include apply-text-inherited();
    @include apply-text-pallete();
    @include apply-typography-header3();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @include apply-typography-header3(true);
    }

    & p {
        @include apply-text-inherited();
        @include apply-text-pallete();
        @include apply-typography-header3();

        &.is-responsive {
            @include apply-typography-header3(true);
        }
    }

    > a {
        color: inherit;

        &:hover {
            opacity: 0.85;
        }
    }
}

.h4 {
    @include apply-text-inherited();
    @include apply-text-pallete();
    @include apply-typography-header4();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @include apply-typography-header4(true);
    }

    & p {
        @include apply-text-inherited();
        @include apply-text-pallete();
        @include apply-typography-header4();

        &.is-responsive {
            @include apply-typography-header4(true);
        }
    }
}

.caption {
    @include apply-text-inherited();
    @include apply-text-pallete();
    @include apply-typography-caption();

    margin: 0;
    padding: 0;

    &.is-responsive {
        @include apply-typography-caption(true);
    }

    & p {
        @include apply-text-inherited();
        @include apply-text-pallete();
        @include apply-typography-caption();

        &.is-responsive {
            @include apply-typography-caption(true);
        }
    }
}

.p-1 {
    @include apply-text-inherited();
    @include apply-text-pallete();
    @include apply-typography-p1();

    margin: 0;
    padding: 0;

    & + & {
        margin-top: 1em;
    }

    & p {
        @include apply-text-inherited();
        @include apply-text-pallete();
        @include apply-typography-p1();
    }
}

strong,
b {
    font-weight: 600;
    font-family: var(--font-family-bold);
}

.is-branded {
    display: block;
    color: var(--color-primary);

    .badoo & {
        --color-primary: #783cfa;
    }

    .has-inlined-text &,
    &.is-inlined {
        display: inline;
    }
}

.is-italic {
    margin-right: 0.2ch;
    font-style: italic;
}

.has-underline {
    background-image: linear-gradient(0deg, var(--color-primary) 0%, var(--color-primary) 100%);
    background-position: 0 calc(100% - 4px);
    background-repeat: no-repeat;
    background-size: 100% 2px;
    transition: background-size 400ms ease-in;

    .is-opened-mobile-nav & {
        text-decoration-color: transparent;
    }

    // @todo: move out in separate context
    .animations-collection & {
        background-size: 0 2px;

        &.animated {
            animation: draw-underline 400ms ease-in forwards;
        }
    }
}

.has-circle {
    position: relative;
    display: inline-block;
    margin-right: 0.2ch;
    white-space: nowrap;

    &::before {
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        box-sizing: border-box;
        width: calc(100% + 20px);
        height: 60%;
        content: '';
        border: 2px solid var(--color-primary);
        border-radius: 100%;
        transform: translate(-50%, -50%);
        pointer-events: none;

        .animations-collection & {
            content: none;
        }

        .is-opened-mobile-nav & {
            border-color: transparent;
        }
    }

    .inner-circle {
        position: absolute;
        z-index: -1;
        top: 50%;
        left: 50%;
        box-sizing: border-box;
        width: calc(100% + 20px);
        transform: translate(-50%, -50%);
        pointer-events: none;
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
    }

    .animations-collection &.animated .inner-circle {
        animation: draw-dash 600ms linear forwards;
    }

    .is-italic {
        margin-right: 0;
    }
}

// some overrides for now - due to designs
.text-extra-space {
    .h4 {
        line-height: 1.15;
    }
}

.formatted-text {
    font-family: var(--font-family-book);

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
        font-size: 1em;
        line-height: 1.4;
    }

    b + *,
    strong + *,
    p + * {
        margin-top: 1em;
    }

    // styles from lever
    b[style] {
        font-size: inherit !important;
    }

    ul,
    ol {
        padding-left: 0;
    }

    ol li + li,
    ul li + li {
        margin-top: 11px;
    }

    a {
        display: inline;
        color: #141414 !important;
        text-decoration: none;
        font-weight: var(--font-weight-medium);
        transition: 0.2s ease opacity;

        @include apply-link-custom-underlined();
    }
}

.is-one-line {
    white-space: nowrap;
}

// Debug
@mixin apply-typography-debugger($color, $name) {
    position: relative;
    background-color: rgba($color, 0.1);

    &::after {
        position: absolute;
        top: -10px;
        left: 0;
        content: '#{$name}';
        color: #999;
        font-size: 10px;
        line-height: 1;
        font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue,
            Arial, sans-serif;
    }
}

//.title { @include apply-typography-debugger(#0D47A1, 'title') }
//.h1 { @include apply-typography-debugger(#1565C0, 'h1') }
//.h2 { @include apply-typography-debugger(#1976D2, 'h2') }
//.h3 { @include apply-typography-debugger(#1E88E5, 'h3') }
//.p-1 { @include apply-typography-debugger(#2196F3, 'p1') }
//.p-2 { @include apply-typography-debugger(#42A5F5, 'p2') }
//.p-3 { @include apply-typography-debugger(#64B5F6, 'p3') }
