.category {
    padding: 32px 20px;
    border-radius: 24px 24px 0px 0px;

    @include apply-screen-size(tablet-md) {
        padding: 48px 60px;
        border-radius: 40px 40px 0px 0px;
    }
}

.category__content {
    max-width: 100%;
    overflow: auto;
}

.category__cards {
    --category-vertical-gap: 16px;
    --category-horizontal-gap: 24px;

    min-width: 1200px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: var(--category-vertical-gap);
    grid-row-gap: var(--category-horizontal-gap);

    .category--one-row & {
        grid-template-rows: repeat(1, 1fr);
    }

    @include apply-screen-size(tablet-md) {
        --category-vertical-gap: 24px;
        --category-horizontal-gap: 32px;
    }
}

.category__breadcrumbs {
    --category-breadcrumbs-bottom-gap: 12px;

    margin-bottom: var(--category-breadcrumbs-bottom-gap);

    @include apply-screen-size(tablet-md) {
        --category-breadcrumbs-bottom-gap: 18px;
    }
}

.category__cta {
    position: relative;
    min-height: 40px;

    .cta-box {
        padding: 0;
    }
}

.category__header + .category__content {
    margin-top: 24px;

    @include apply-screen-size(tablet-md) {
        margin-top: 32px;
    }
}

.category__content + .category__cta {
    margin-top: 24px;

    @include apply-screen-size(tablet-md) {
        margin-top: 48px;
    }
}

.category--related-articles {
    --section-bottom-gap: -48px;
    --section-top-gap: 48px;

    margin-bottom: var(--section-bottom-gap);
    margin-top: var(--section-top-gap);

    @include apply-screen-size(tablet-md) {
        --section-bottom-gap: -72px;
        --section-top-gap: 72px;
    }
}
