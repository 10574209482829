.page-section {
    display: block;
    background-color: #fff;

    &:empty {
        display: none;
    }

    .cta-box {
        padding: 0;
    }
}

.page-section--article-intro {
    --page-section-border-radius: 24px;
    background-color: #fffcf3;
    border-radius: var(--page-section-border-radius);

    &.page-section--padded {
        padding-left: 16px;
        padding-right: 16px;
    }

    &.page-section--limited-width {
        max-width: 1016px;
        margin-top: -24px;
        margin-bottom: 48px;
        margin-left: 20px;
        margin-right: 20px;
    }

    @include apply-screen-size(tablet-md) {
        --page-section-border-radius: 40px;


        &.page-section--padded {
            padding-left: 90px;
            padding-right: 90px;
        }

        &.page-section--limited-width {
            max-width: 1016px;
            margin-top: -48px;
            margin-bottom: 72px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.page-section--limited-width {
    max-width: 840px;
    margin: 0 auto;
}

.page-section--image {
    --section-vertical-gap: 48px;

    max-width: 870px;
    margin: var(--section-vertical-gap) auto;

    @include apply-screen-size(tablet-md) {
        --section-vertical-gap: 72px;
    }
}

.page__content + .page__footer {
    --section-vertical-gap: 48px;

    margin-top: var(--section-vertical-gap);

    @include apply-screen-size(tablet-md) {
        --section-vertical-gap: 72px;
    }
}

.page-section--article-splash-screen .page__media-image {
    min-width: 50vw;
}

.page-section--primary {
    background-color: var(--color-primary);
}

.page-section--primary-partly {
    &::before {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 40%;
        background-color: var(--color-primary);
    }
}

.page-section--secondary {
    --text-color: var(--color-primary);

    background-color: var(--color-base);
    color: #fff;
}

.page-section--accent {
    background-color: #ffedb4;
}

.page-section--padded {
    padding: var(--content-section-padding-vertical) 0;
}

.page-section--footer-cta {
    padding: 0 20px;

    @include apply-screen-size(tablet-md) {
        padding: 0;
    }

    &.page-section--padded {
        padding: var(--content-section-padding-vertical) 20px;

        @include apply-screen-size(tablet-md) {
            padding: var(--content-section-padding-vertical) 0;
        }
    }
}

.page-section__inner {
    max-width: var(--content-max-width);
    margin: 0 auto;
    padding: 0 var(--content-section-padding-horizontal);

    .page-section--mobile-full-width & {
        @include apply-screen-size(tablet-md) {
            padding: 0 var(--content-section-padding-horizontal);
        }

        padding: 0;
    }
}
